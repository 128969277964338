/*
 * eu-digital-green-certificates/ dgca-issuance-web
 *
 * (C) 2021, T-Systems International GmbH
 *
 * Deutsche Telekom AG and all other contributors /
 * copyright owners license this file to you under the Apache
 * License, Version 2.0 (the "License"); you may not use this
 * file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';

import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

import { jsPDF } from "jspdf";

//import logo from '../assets/images/eu_flag_neu.png';
import logo from '../assets/images/Escudo_GOES.png';
//import logo from '../assets/images/EU_logo_big.png';
import logo_covid_19 from '../assets/images/Logo_Vacuna_Covid.png';
import flag_el_salvador from '../assets/images/Bandera_SV.png';
import logo_goes_negro from '../assets/images/Logo_GOES_horizontal.png';
import folding_instruction from '../assets/images/instrucciones.png';
import logo_goes_clean_alrevez from '../assets/images/logo_goes_clean_alrevez.png';
import textura_frontal from '../assets/images/Textura_frontal.png';
import textura_qr from '../assets/images/Textura_QR.png';

import { AnotherVaccinationEntry, EUDCC1, RecoveryEntry, TestEntry, VaccinationEntry } from '../generated-files/dgc-combined-schema';
import { IValueSetList, Value_Sets, getValueSetDisplay, IValueSet } from './useValueSet';
import utils from './utils';
// import pdfParams from '../pdf-settings.json';

require('../assets/SCSS/fonts/arial-normal.js');
require('../assets/SCSS/fonts/arial-bold.js');
require('../assets/SCSS/fonts/arial-italic.js');
require('../assets/SCSS/fonts/arial-bolditalic.js');

const mm2point = (mm: number): number => {
    return mm * 2.83465;
}

interface IPageParameter {
    a6width: number,
    a6height: number,

    marginTop: number,
    marginBottom: number,
    marginLeft: number,
    marginRight: number,

    paddingLeft: number,
    paddingInnerLeft: number,
    paddingRight: number,
    paddingTop: number,
    paddingInnerTop: number,
    paddingBottom: number,

    lineHeight: number,
    fontSize: number,
    fontSize8: number,
    fontSize9: number,
    fontSize10: number,
    fontSize11: number,
    fontSize12: number,
    fontSize14: number,
    fontSize13: number,
    fontSize15: number,
    fontSize16: number,
    lineHeight9: number,
    lineHeight10: number,
    lineHeight11: number,
    lineHeight12: number,
    lineHeight14: number,
    lineHeight15: number,
    lineHeight16: number,
    headerLineHeight: number,
    headerFontSize: number,
    smallHeaderLineHeight: number,
    smallHeaderFontSize: number,
    space: number
}

interface IWordInfo {
    text: string;
    wordLength: number;
}

const usePdfGenerator = (
    qrCodeCanvasElementProp: any,
    eudccProp: EUDCC1 | undefined,
    valueSetListProp: IValueSetList | undefined,
    issuerCountryCodeProp: string,
    onIsInit: (isInit: boolean) => void,
    onIsReady: (isReady: boolean) => void) => {

    const { t } = useTranslation();
    const french = i18n.getDataByLanguage('es');

    //A4 210 x 297 mm or 2480 x 3508 pixels or 595 × 842 points
    //A6 105 x 74 mm or 1240 x 1748 pixels or 298 × 420 points

    const params: IPageParameter = {
        a6width: 612 / 2,
        a6height: 791 / 2,

        marginTop: mm2point(15),
        marginBottom: mm2point(15),
        marginLeft: mm2point(15),
        marginRight: mm2point(15),

        paddingLeft: mm2point(10),
        paddingInnerLeft: mm2point(3),
        paddingRight: mm2point(10),
        paddingTop: mm2point(10),
        paddingInnerTop: mm2point(4),
        paddingBottom: mm2point(10),

        lineHeight: 14,
        fontSize: 11,
        fontSize8: 8,
        fontSize9: 9,
        fontSize10: 10,
        fontSize11: 11,
        fontSize12: 12,
        fontSize13: 13,
        fontSize14: 14,
        fontSize15: 15,
        fontSize16: 16,
        lineHeight9: 9,
        lineHeight10: 10,
        lineHeight11: 11,
        lineHeight12: 12,
        lineHeight14: 14,
        lineHeight15: 15,
        lineHeight16: 16,
        headerLineHeight: 21,
        headerFontSize: 21,
        smallHeaderLineHeight: 17,
        smallHeaderFontSize: 17,
        space: 2
    }

    const lblLength = params.a6width - params.paddingInnerLeft - params.paddingRight;
    // const pageMiddle = params.a6width / 2;

    const [isInit, setIsInit] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);

    const [firstPageIsReady, setFirstPageIsReady] = React.useState(false);
    const [secondPageIsReady, setSecondPageIsReady] = React.useState(false);
    const [thirdPageIsReady, setThirdPageIsReady] = React.useState(false);
    const [fourthPageIsReady, setFourthPageIsReady] = React.useState(false);

    const [pdf, setPdf] = React.useState<jsPDF>();

    const [eudcc, setEudcc] = React.useState<EUDCC1>();
    const [issuerCountryCode, setIssuerCountryCode] = React.useState('');
    const [vaccinationSet, setVaccinationSet] = React.useState<VaccinationEntry>();
    const [fullVaccinationSet, setFullVaccinationSet] = React.useState<AnotherVaccinationEntry[]>([]);
    const [testSet, setTestSet] = React.useState<TestEntry>();
    const [recoverySet, setRecoverySet] = React.useState<RecoveryEntry>();
    const [ci, setCi] = React.useState<string>();
    const [co, setCo] = React.useState<string>();
    const [qrCodeCanvasElement, setQrCodeCanvasElement] = React.useState<any>();

    const [countryCodeValueSet, setCountryCodeValueSet] = React.useState<IValueSet>();
    const [vacMedsData, setVacMedsData] = React.useState<IValueSet>();
    const [diseaseAgentsData, setDiseaseAgentsData] = React.useState<IValueSet>();
    const [vaccineManufacturers, setVaccineManufacturers] = React.useState<IValueSet>();
    const [vaccines, setVaccines] = React.useState<IValueSet>();
    const [testManufacturersValueSet, setTestManufacturersValueSet] = React.useState<IValueSet>();
    const [testResultValueSet, setTestResultValueSet] = React.useState<IValueSet>();
    const [testTypeValueSet, setTestTypeValueSet] = React.useState<IValueSet>();

    // on mount generate pdf
    React.useEffect(() => {
        const _pdf = new jsPDF("p", "pt", "a4", true);

        _pdf.setFont('arial', 'normal');
        _pdf.setLineHeightFactor(1);
        //_pdf.addPage();

        setPdf(_pdf);
    }, [])

    // on pdf generated set all static data
    React.useEffect(() => {
        if (pdf) {
            printDottedLine();
            prepareThirdPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdf])

    // set isInit true if all ValueSets are loaded and pdf is initialized
    React.useEffect(() => {
        if (pdf && diseaseAgentsData && vaccines && vaccineManufacturers && vacMedsData && testResultValueSet && testManufacturersValueSet) {
            setIsInit(true);
        }
    }, [pdf, diseaseAgentsData, vaccines, vaccineManufacturers, vacMedsData, testResultValueSet, testManufacturersValueSet, countryCodeValueSet])

    React.useEffect(() => {
        if (onIsInit) {
            onIsInit(isInit);
        }
    }, [isInit, onIsInit])

    React.useEffect(() => {
        if (onIsReady) {
            onIsReady(isReady);
        }
    }, [isReady, onIsReady])

    React.useEffect(() => {
        if (firstPageIsReady && secondPageIsReady && thirdPageIsReady && fourthPageIsReady) {
            setIsReady(true);
        }
    }, [firstPageIsReady, secondPageIsReady, thirdPageIsReady, fourthPageIsReady])

    // on receiving eudcc obj set specific ValueSet
    React.useEffect(() => {
        if (eudccProp) {
            setEudcc(eudccProp);

            const vacc: [VaccinationEntry] = eudccProp.v as [VaccinationEntry];
            const vacc2: [AnotherVaccinationEntry] = eudccProp.vaccines as [AnotherVaccinationEntry];
            const test: [TestEntry] = eudccProp.t as [TestEntry];
            const recovery: [RecoveryEntry] = eudccProp.r as [RecoveryEntry];

            setVaccinationSet(vacc ? vacc[0] : undefined);
            setFullVaccinationSet(vacc2);
            setTestSet(test ? test[0] : undefined);
            setRecoverySet(recovery ? recovery[0] : undefined);
        }
    }, [eudccProp])

    // on receiving valueSetList obj set specific ValueSet
    React.useEffect(() => {
        if (valueSetListProp) {
            setCountryCodeValueSet(valueSetListProp[Value_Sets.CountryCodes]);
            setVacMedsData(valueSetListProp[Value_Sets.Vaccines]);
            setDiseaseAgentsData(valueSetListProp[Value_Sets.DiseaseAgent]);
            setVaccineManufacturers(valueSetListProp[Value_Sets.VaccinesManufacturer]);
            setVaccines(valueSetListProp[Value_Sets.VaccineType]);
            setTestManufacturersValueSet(valueSetListProp[Value_Sets.TestManufacturer]);
            setTestResultValueSet(valueSetListProp[Value_Sets.TestResult]);
            setTestTypeValueSet(valueSetListProp[Value_Sets.TestType]);
        }
    }, [valueSetListProp])

    // set qrcode element from props
    React.useEffect(() => {
        if (qrCodeCanvasElementProp) {
            setQrCodeCanvasElement(qrCodeCanvasElementProp);
        }
    }, [qrCodeCanvasElementProp])

    React.useEffect(() => {
        if (issuerCountryCodeProp) {
            setIssuerCountryCode(issuerCountryCodeProp);
        }
    }, [issuerCountryCodeProp])

    // set fourth page for vaccination
    React.useEffect(() => {
        if (vaccinationSet && isInit) {
            setCi(vaccinationSet.ci);
            setCo(vaccinationSet.co);
            prepareFourthPageVaccination();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vaccinationSet, isInit])

    // set fourth page for test
    React.useEffect(() => {
        if (testSet && isInit) {
            setCi(testSet.ci);
            setCo(testSet.co);
            prepareFourthPageTest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testSet, isInit])

    // set fourth page for recovery
    React.useEffect(() => {
        if (recoverySet && isInit) {
            setCi(recoverySet.ci);
            setCo(recoverySet.co);
            prepareFourthPageRecovery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recoverySet, isInit])

    React.useEffect(() => {
        if (qrCodeCanvasElement && ci && isInit && eudcc) {
            prepareSecondPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrCodeCanvasElement, ci, isInit, eudcc]);

    React.useEffect(() => {
        if (co && isInit && eudcc) {
            prepareFirstPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [co, isInit, eudcc]);

    const printDottedLine = () => {
        if (pdf) {
            pdf.setPage(1);
            let curX = 0;
            let curY = 0;
            let deltaX = mm2point(10);
            let deltaY = mm2point(10);

            pdf.line(curX, params.a6height, curX + deltaX, params.a6height);
            pdf.line(params.a6width * 2 - curX, params.a6height, params.a6width * 2 - curX - deltaX, params.a6height);

            pdf.line(params.a6width, curY, params.a6width, curY + deltaY);
            pdf.line(params.a6width, params.a6height * 2 - deltaY, params.a6width, params.a6height * 2);

            pdf.line(params.a6width, params.a6height - deltaY / 2, params.a6width, params.a6height + deltaY / 2);
            pdf.line(params.a6width - deltaX / 2, params.a6height, params.a6width + deltaX / 2, params.a6height);

            // for (let page = 1; page < 3; page++) {
            //     pdf.setPage(page)
            //     let curX = 0 + params.marginLeft;
            //     let curY = params.a6height;
            //     let xTo = params.a6width * 2 - params.marginRight;
            //     let deltaX = 3;
            //     let deltaY = 3;
            //     while (curX <= xTo) {
            //         pdf.line(curX, curY, curX + deltaX, curY);
            //         curX += 2 * deltaX;
            //     }

            //     curX = params.a6width;
            //     curY = 0 + params.marginTop;
            //     let yTo = params.a6height * 2 - params.marginBottom;
            //     while (curY <= yTo) {
            //         pdf.line(curX, curY, curX, curY + deltaY);
            //         curY += 2 * deltaY;
            //     }
            // }
        }
    }

    const prepareFirstPage = () => {
        if (pdf && french && eudcc && issuerCountryCode) {
            for (let page = 1; page < 2; page++) {
                let x = 0;
                let y = 0;
                let xfondo = 0;
                let yfondo = 0;
                let xlogo = 0;
                let ylogo = 0;
                let xH1 = 0;
                let yH1 = 0;
                let xH2 = 0;
                let yH2 = 0;
                let xH3 = 0;
                let yH3 = 0;
                let xRectRound = 0;
                let yRectRound = 0;
                let xlogoCovid = 0;
                let ylogoCovid = 0;
                let xflag = 0;
                let yflag = 0;
                let xRectBorder = 0;
                let yRectBorder = 0;
                let xL1 = 0;
                let yL1 = 0;
                let xL2 = 0;
                let yL2 = 0;
                let xL3 = 0;
                let yL3 = 0;
                let xLabelNombre = 0;
                let yLabelNombre = 0;
                let xLabelFecha = 0;
                let yLabelFecha = 0;
                let xLabelDUI = 0;
                let yLabelDUI = 0;

                pdf.setPage(page);
                if (page === 1) {
                    x = params.a6width;
                    y = params.a6height;
                    xfondo = x;
                    yfondo = y;
                    xlogo = x;
                    ylogo = y;
                    xH1 = x;
                    yH1 = y;
                    xH2 = x;
                    yH2 = y;
                    xH3 = x;
                    yH3 = y;
                    xRectRound = x;
                    yRectRound = y;
                    xlogoCovid = x;
                    ylogoCovid = y;
                    xflag = x;
                    yflag = y;
                    xRectBorder = x;
                    yRectBorder = y;
                    xL1 = x;
                    yL1 = y;
                    xL2 = x;
                    yL2 = y;
                    xL3 = x;
                    yL3 = y;
                    xLabelNombre = x;
                    yLabelNombre = y;
                    xLabelFecha = x;
                    yLabelFecha = y;
                    xLabelDUI = x;
                    yLabelDUI = y;
                }

                x += params.a6width / 2;
                y += mm2point(40);

                let rectWith = params.a6width;
                let rectHeight = params.a6height;

                let rectRoudWith = params.a6width - 20;
                let rectRoudHeight = 192;

                //let logoWidth = 131.5;
                //let logoHeight = 103.5;

                let logoWidth = 52;
                let logoHeight = 54;

                let logoCovidWidth = 84;
                let logoCovidHeight = 27;

                let flagWidth = 48;
                let flagHeight = 27;

                let rectBorderWith = params.a6width - 45;
                let rectBorderHeight = 60;

                if (page === 1) {
                    x = params.a6width + (params.a6width - logoWidth) / 2;
                    xlogo = x;
                    xfondo = params.a6width + (params.a6width - rectWith);
                    xRectRound = params.a6width + (params.a6width - rectRoudWith) / 2;
                    xlogoCovid = params.a6width + (params.a6width - logoCovidWidth) / 2;
                    xflag = params.a6width + (params.a6width - flagWidth) / 2;
                    xRectBorder = params.a6width + (params.a6width - rectBorderWith) / 2;
                } else {
                    x = (params.a6width - logoWidth) / 2;
                    xlogo = x;
                    xfondo = (params.a6width - rectWith);
                    xRectRound = (params.a6width - rectRoudWith) / 2;
                    xlogoCovid = (params.a6width - logoCovidWidth) / 2;
                    xflag = (params.a6width - flagWidth) / 2;
                    xRectBorder = (params.a6width - rectBorderWith) / 2;
                }
                y += params.headerLineHeight + mm2point(10);
                ylogo += params.headerLineHeight;
                yfondo += params.headerLineHeight - params.headerLineHeight;
                pdf.setFillColor(45, 82, 168)
                pdf.rect(xfondo, yfondo, rectWith - 29, rectHeight - 30, 'F')
                pdf.addImage(logo, 'png', xlogo - 14, ylogo + 10, logoWidth, logoHeight);
                xlogo += logoWidth / 2;
                ylogo += logoHeight / 2 + params.lineHeight15 / 2 + 2;
                //setTextColorWhite();
                //pdf.setFont('arial', 'bold');
                //pdf.setFontSize(26);
                //pdf.text(issuerCountryCode, xlogo, ylogo, { align: 'center' });                
                //TODO: country
                // if(pdfParams.issuer_country_code) {
                //     pdf.text(pdfParams.issuer_country_code, x, y, { align: 'center' });
                // }               

                setTextColorWhite();
                pdf.setFont('arial', 'bold');
                pdf.setFontSize(18);

                let header = t('translation:pdfGreenCertificate');
                header = pdf.splitTextToSize(header, lblLength);

                if (page === 1) {
                    x = params.a6width + params.a6width / 2;
                    xH1 = x;
                    xH2 = x;
                    xH3 = x;
                    xL1 = x;
                    xL2 = x;
                    xL3 = x;
                    xLabelNombre = x;
                    xLabelFecha = x;
                    xLabelDUI = x;
                } else {
                    x = params.a6width / 2;
                    xH1 = x;
                    xH2 = x;
                    xH3 = x;
                    xL1 = x;
                    xL2 = x;
                    xL3 = x;
                    xLabelNombre = x;
                    xLabelFecha = x;
                    xLabelDUI = x;
                }

                yH1 += params.headerLineHeight + mm2point(45);
                yH2 += params.headerLineHeight + mm2point(52);
                yH3 += params.headerLineHeight + mm2point(59);
                yRectRound += params.headerLineHeight + mm2point(68);
                ylogoCovid += params.headerLineHeight + mm2point(73);
                yflag += params.headerLineHeight + mm2point(73);
                yRectBorder += params.headerLineHeight + mm2point(87);
                yL1 += params.headerLineHeight + mm2point(102);
                yLabelNombre += params.headerLineHeight + mm2point(93);
                yL2 += params.headerLineHeight + mm2point(123);
                yL3 += params.headerLineHeight + mm2point(123);
                yLabelFecha += params.headerLineHeight + mm2point(117);
                yLabelDUI += params.headerLineHeight + mm2point(117);

                header = french.translation.pdfGreenCertificate;
                header = pdf.splitTextToSize(header, lblLength);
                pdf.text(header, xH1 - 14, yH1 - 23, { align: 'center', maxWidth: lblLength });
                setTextColorWhite();
                pdf.setFont('arial', 'normal');
                pdf.setFontSize(params.fontSize14);
                pdf.text('Certificat numérique européen COVID', xH2 - 14, yH2 - 23, { align: 'center', maxWidth: lblLength });
                setTextColorWhite();
                pdf.setFont('arial', 'normal');
                pdf.setFontSize(params.fontSize14);
                pdf.text('Certificado COVID digital de la UE', xH3 - 14, yH3 - 23, { align: 'center', maxWidth: lblLength });

                pdf.setFillColor(255, 255, 255)
                //pdf.setFillColor(230, 233, 240)
                pdf.roundedRect(xRectRound, yRectRound - 20, rectRoudWith - 28, rectRoudHeight - 29, 14, 14, 'F')
                pdf.addImage(textura_frontal, 'png', xRectRound + 0.2, yRectRound - 19.8, rectRoudWith - 28.5, rectRoudHeight - 29.5);

                xlogoCovid -= mm2point(28);
                pdf.addImage(logo_covid_19, 'png', xlogoCovid, ylogoCovid - 20, logoCovidWidth, logoCovidHeight);

                xflag += mm2point(24);
                pdf.addImage(flag_el_salvador, 'png', xflag, yflag - 20, flagWidth, flagHeight);

                pdf.setDrawColor(105, 114, 129);
                pdf.roundedRect(xRectBorder, yRectBorder - 20, rectBorderWith - 29, rectBorderHeight - 5, 10, 10, 'D')

                pdf.setTextColor(105, 114, 129);
                pdf.setFont('arial', 'normal');
                pdf.setFontSize(7);
                const lblLengthSmall = params.a6width - 60 - 60;
                var labelName = pdf.splitTextToSize('Surname(s) and forename(s) / Nom(s) de famille et prénom(s) / Nombres y apellidos', lblLengthSmall);
                pdf.text(labelName, xL1 - 14, yL1 - 23, { align: 'center', maxWidth: lblLengthSmall });

                pdf.setTextColor(49, 57, 69);
                pdf.setFont('arial', 'bold');
                pdf.setFontSize(11);
                const valueName = pdf.splitTextToSize((eudcc!.nam!.gnt + ' ' ? eudcc!.nam!.gnt + ' ' : '') + (eudcc!.nam!.fnt ? eudcc!.nam!.fnt : ''), lblLength);
                pdf.text(valueName, xLabelNombre - 14, yLabelNombre - 20, { align: 'center', maxWidth: lblLengthSmall });

                pdf.setTextColor(105, 114, 129);
                pdf.setFont('arial', 'normal');
                pdf.setFontSize(7);
                const lblLengthUltraSmall = params.a6width - 110 - 110;
                var labelFecha = pdf.splitTextToSize('Date of birth / Date de naissance / Fecha de nacimiento', lblLengthUltraSmall);
                xL2 -= mm2point(26);
                pdf.text(labelFecha, xL2 - 14, yL2 - 36, { align: 'center', maxWidth: lblLengthUltraSmall });

                pdf.setTextColor(49, 57, 69);
                pdf.setFont('arial', 'bold');
                pdf.setFontSize(11);
                const fecha_nac = eudcc.dob!.split('-');
                let fecha_format_sv = fecha_nac[2] + '-' + fecha_nac[1] + '-' + fecha_nac[0];
                const valueFecha = pdf.splitTextToSize((fecha_format_sv ? fecha_format_sv : ''), lblLength);
                xLabelFecha -= mm2point(26);
                pdf.text(valueFecha, xLabelFecha - 14, yLabelFecha - 31, { align: 'center', maxWidth: lblLengthSmall });

                pdf.setTextColor(105, 114, 129);
                pdf.setFont('arial', 'normal');
                pdf.setFontSize(7);
                var labelDUI = pdf.splitTextToSize('Unique certificate identifier / Identifiant unique du certificat / ID', lblLengthUltraSmall + 10);
                xL3 += mm2point(21);
                pdf.text(labelDUI, xL3 - 14, yL3 - 36, { align: 'center', maxWidth: lblLengthUltraSmall + 10 });

                pdf.setTextColor(49, 57, 69);
                pdf.setFont('arial', 'bold');
                pdf.setFontSize(11);
                const valueDUI = pdf.splitTextToSize((eudcc!.dui ? eudcc!.dui : ''), lblLength);
                xLabelDUI += mm2point(21);
                pdf.text(valueDUI, xLabelDUI - 14, yLabelDUI - 31, { align: 'center', maxWidth: lblLengthSmall });
            }

            setFirstPageIsReady(true);
        }
    }

    const prepareSecondPage = () => {
        if (pdf && eudcc && ci && qrCodeCanvasElement && vaccinationSet && french) {

            const space = mm2point(5);

            const img = (qrCodeCanvasElement as HTMLCanvasElement).toDataURL("image/png,base64");
            const canvasWidth = mm2point(30);

            for (let page = 1; page < 2; page++) {
                let x = 0;
                let y = 0;
                let xfondo = 0;
                let yfondo = 0;
                let xlogo = 0;
                let ylogo = 0;
                let xL1 = 0;
                let yL1 = 0;
                let xL2 = 0;
                let yL2 = 0;
                let xL3 = 0;
                let yL3 = 0;
                let xL4 = 0; //cuarta dosis
                let yL4 = 0;
                let xL5 = 0; //quinta dosis
                let yL5 = 0;
                let xL6 = 0; //sexta dosis
                let yL6 = 0;
                let xLabelFechaDosis = 0;
                let yLabelFechaDosis = 0;
                let xLabelFechaDosis2 = 0;
                let yLabelFechaDosis2 = 0;
                let xLabelFechaDosis3 = 0;
                let yLabelFechaDosis3 = 0;                 

                let rectRoudWith = params.a6width - 23;
                let rectRoudHeight = 350;

                let logoWidth = 240;
                let logoHeight = 40;

                pdf.setPage(page);
                if (page === 1) {
                    y = params.a6height - space;
                    yfondo = y;
                    ylogo = y;
                    yL1 = y;
                    yL2 = y;
                    yL3 = y;
                    yL4 = y;//cuarta dosis
                    yL5 = y;//quinta dosis
                    yL6 = y;//sexta dosis
                    yLabelFechaDosis = y;
                    yLabelFechaDosis2 = y;
                    yLabelFechaDosis3 = y;
                } else {
                    x = params.a6width;
                    xfondo = (params.a6width - rectRoudWith) / 2;
                    xlogo = (params.a6width - logoWidth) / 2;
                    xL1 = params.a6width / 2;
                    xL2 = xL1;
                    xL3 = xL1;
                    xL4 = xL1;//cuarta dosis
                    xL5 = xL1;//quinta dosis
                    xL6 = xL1;//sexta dosis
                    xLabelFechaDosis = xL1;
                }
                x += (params.a6width - canvasWidth) / 2;
                y += params.paddingTop + mm2point(10);
                xfondo += (params.a6width - rectRoudWith) / 2;
                yfondo += params.headerLineHeight + mm2point(2);
                xlogo += (params.a6width - logoWidth) / 2;
                ylogo += params.headerLineHeight + mm2point(112);
                xL1 += params.a6width / 2;
                yL1 += params.headerLineHeight + mm2point(55);
                xL2 += params.a6width / 2;
                yL2 += params.headerLineHeight + mm2point(55);
                xL3 += params.a6width / 2;
                yL3 += params.headerLineHeight + mm2point(78);
                xL4 += params.a6width / 2; //cuarta dosis
                yL4 += params.headerLineHeight + mm2point(78);
                xL5 += params.a6width / 2; //quinta dosis
                yL5 += params.headerLineHeight + mm2point(101);
                xL6 += params.a6width / 2; //sexta dosis
                yL6 += params.headerLineHeight + mm2point(101);
                let tempxD1 = xL1;
                let tempxD2 = xL2;
                let tempxD3 = xL3;
                let tempxD4 = xL4;//cuartda dosis
                let tempxD5 = xL5;//quinta dosis
                let tempxD6 = xL6;//sexta dosis 
                xLabelFechaDosis += params.a6width / 2;
                yLabelFechaDosis += params.headerLineHeight + mm2point(62);
                xLabelFechaDosis2 += params.a6width / 2;
                yLabelFechaDosis2 += params.headerLineHeight + mm2point(85);
                xLabelFechaDosis3 += params.a6width / 2;
                yLabelFechaDosis3 += params.headerLineHeight + mm2point(108);
                let tempManD1 = xLabelFechaDosis;
                let tempManD2 = xLabelFechaDosis;
                let tempManD3 = xLabelFechaDosis2;
                let tempManD4 = xLabelFechaDosis2;//cuartda dosis
                let tempManD5 = xLabelFechaDosis3;//quinta dosis
                let tempManD6 = xLabelFechaDosis3;//sexta dosis
                //pdf.setFillColor(230, 233, 240)
                pdf.setFillColor(255, 255, 255)
                pdf.roundedRect(xfondo + 24, yfondo, rectRoudWith - 33, rectRoudHeight - 47, 12, 12, 'F')
                pdf.addImage(textura_qr, 'png', xfondo + 24, yfondo, rectRoudWith - 33, rectRoudHeight - 47);
                pdf.addImage(img, 'png', x + 7, y - 15, canvasWidth, canvasWidth);
                pdf.addImage(logo_goes_negro, 'png', xlogo + 7, ylogo, logoWidth, logoHeight);

                for (let dosis = 0; dosis < fullVaccinationSet.length; dosis++) {
                    pdf.setTextColor(105, 114, 129);
                    pdf.setFont('arial', 'normal');
                    pdf.setFontSize(7);
                    const lblLengthSmall = params.a6width - 60 - 60;
                    if (fullVaccinationSet[dosis].dosis_number !== 5) {
                        var labelDosis1 = pdf.splitTextToSize('Dose ' + fullVaccinationSet[dosis].dosis_number.toString() + ' / Dosis ' + fullVaccinationSet[dosis].dosis_number.toString(), lblLengthSmall);
                    }else{
                        var labelDosis1 = pdf.splitTextToSize('Dose de rappel / Dosis de Refuerzo', lblLengthSmall);
                    }
                    if (dosis == 0) {
                        xL1 = tempxD1 -= mm2point(37);
                    } else if (dosis == 1) {
                        xL1 = tempxD2 += mm2point(4);
                    } else if (dosis == 2) {
                        xL3 = tempxD3 -= mm2point(37);
                    } else if (dosis == 3) {//cuarta dosis
                        xL4 = tempxD4 += mm2point(4)
                    } else if (dosis == 4) {//quinta dosis
                        xL5 = tempxD5 -= mm2point(37)
                    }  else if (dosis == 5) {//sexta dosis
                        xL6 = tempxD6 += mm2point(4)
                    }

                    if (dosis < 2) {
                        pdf.text(labelDosis1, xL1 + 7, yL1 - 32, { maxWidth: lblLengthSmall });
                    } else if (dosis == 3) {
                        pdf.text(labelDosis1, xL4 + 7, yL4 - 32, { maxWidth: lblLengthSmall });
                    }
                    else if( dosis == 2) {
                        pdf.text(labelDosis1, xL3 + 7, yL3 - 32, { maxWidth: lblLengthSmall });
                    }else if(dosis == 4){
                        pdf.text(labelDosis1, xL5 + 7, yL5 - 32, { maxWidth: lblLengthSmall });
                    } else if (dosis == 5){
                        pdf.text(labelDosis1, xL6 + 7, yL6 - 32, { maxWidth: lblLengthSmall });
                    }

                    pdf.setTextColor(49, 57, 69);
                    pdf.setFont('arial', 'bold');
                    pdf.setFontSize(11);
                    let xlabelManufactura = 0;
                    if (dosis == 0) {
                        xlabelManufactura = tempManD1 -= mm2point(37);
                    } else if (dosis == 1) {
                        xlabelManufactura = tempManD2 += mm2point(4);
                    } else if (dosis == 2) {
                        xlabelManufactura = tempManD3 -= mm2point(37);
                    } else if (dosis == 3) { //cuarta dosis
                        xlabelManufactura = tempManD4 += mm2point(4);
                    } else if (dosis == 4) { //quinta dosis
                        xlabelManufactura = tempManD5 -= mm2point(37);
                    } else if (dosis == 5) { //sexta dosis
                        xlabelManufactura = tempManD6 += mm2point(4);
                    }
                    const valueMAnufacturaDosis = pdf.splitTextToSize((fullVaccinationSet[dosis].trademark.toString() ? fullVaccinationSet[dosis].trademark.toString() : ''), lblLength);
                    if (dosis < 2) {
                        pdf.text(valueMAnufacturaDosis, xlabelManufactura + 7, yLabelFechaDosis - 38, { maxWidth: lblLengthSmall });
                    } else if (dosis > 1 && dosis < 4) {
                        pdf.text(valueMAnufacturaDosis, xlabelManufactura + 7, yLabelFechaDosis2 - 38, { maxWidth: lblLengthSmall });
                    } else if (dosis > 3  && dosis < 5) {
                        pdf.text(valueMAnufacturaDosis, xlabelManufactura + 7, yLabelFechaDosis3 - 38, { maxWidth: lblLengthSmall });
                    }

                    pdf.setTextColor(49, 57, 69);
                    pdf.setFont('arial', 'bold');
                    pdf.setFontSize(11);
                    const valueProductoDosis = pdf.splitTextToSize((fullVaccinationSet[dosis].product.toString() ? fullVaccinationSet[dosis].product.toString() : ''), lblLength);
                    if (dosis < 2) {
                        pdf.text(valueProductoDosis, xlabelManufactura + 7, yLabelFechaDosis - 24, { maxWidth: lblLengthSmall });
                    } else if (dosis > 1 && dosis < 4) {
                        pdf.text(valueProductoDosis, xlabelManufactura + 7, yLabelFechaDosis2 - 24, { maxWidth: lblLengthSmall });
                    } else if (dosis > 3  && dosis < 5) {
                        pdf.text(valueProductoDosis, xlabelManufactura + 7, yLabelFechaDosis3 - 24, { maxWidth: lblLengthSmall });
                    }

                    pdf.setTextColor(49, 57, 69);
                    pdf.setFont('arial', 'bold');
                    pdf.setFontSize(11);
                    const fecha_nac = new Date(fullVaccinationSet[dosis].datetime!.replace(/ /g, "T"));
                    let fecha_format_sv = (("0" + fecha_nac.getDate()).slice(-2)) + '-' + (("0" + (fecha_nac.getMonth() + 1)).slice(-2)) + '-' + fecha_nac.getFullYear();
                    const valueFechaDosis = pdf.splitTextToSize((fecha_format_sv ? fecha_format_sv : ''), lblLength);
                    if (dosis < 2) {
                        pdf.text(valueFechaDosis, xlabelManufactura + 7, yLabelFechaDosis - 10, { maxWidth: lblLengthSmall });
                    } else if (dosis > 1 && dosis < 4) {
                        pdf.text(valueFechaDosis, xlabelManufactura + 7, yLabelFechaDosis2 - 10, { maxWidth: lblLengthSmall });
                    } else if (dosis > 3  && dosis < 5) {
                        pdf.text(valueFechaDosis, xlabelManufactura + 7, yLabelFechaDosis3 - 10, { maxWidth: lblLengthSmall });
                    }
                }

                /*pdf.setTextColor(105, 114, 129);
                pdf.setFont('arial', 'normal');
                pdf.setFontSize(params.fontSize8);
                var labelDosis2 = pdf.splitTextToSize('Dose 2 / Dosis 2', lblLengthSmall);                
                xL2 += mm2point(10);
                pdf.text(labelDosis2, xL2, yL2, { align: 'center', maxWidth: lblLengthSmall });         
                
                pdf.setTextColor(105, 114, 129);
                pdf.setFont('arial', 'normal');
                pdf.setFontSize(params.fontSize8);
                var labelDosis3 = pdf.splitTextToSize('Dose 3 / Dosis 3', lblLengthSmall);                
                xL3 -= mm2point(26);
                pdf.text(labelDosis3, xL3, yL3, { align: 'center', maxWidth: lblLengthSmall });*/

                //For the labels on the left side
                if (page === 1) {
                    x = params.paddingLeft;
                } else {
                    x = params.a6width + params.paddingInnerLeft;
                }

                /*y += imageHeight + space;
                pdf.setFontSize(params.fontSize11);*/

                /*y = printHorizontalBlockPerson(x, y,
                    t('translation:pdfSurname'),
                    french.translation.pdfSurname,
                    (eudcc!.nam!.fnt + ' ') + (eudcc!.nam!.gnt ? eudcc!.nam!.gnt : ''));*/

                /*y = printHorizontalBlockPerson(x, y,
                    t('translation:pdfDateOfBirth'),
                    french.translation.pdfDateOfBirth,
                    eudcc.dob);*/

                /*y = printHorizontalBlockPerson(x, y,
                    t('translation:pdfCi'),
                    french.translation.pdfCi,
                    ci);*/
            }

            setSecondPageIsReady(true);
        }
    }

    //La parte donde muestra como doblar la hoja... pero este es el invertido... NO TIENE QUE APARECER
    const prepareThirdPage = () => {
        if (pdf) {
            for (let page = 1; page < 2; page++) {
                pdf.setPage(page);
                if (page === 1) {
                    prepareThirdPageRotated();
                } else {

                    /*let lblLength = params.a6width - params.paddingLeft - params.paddingRight - mm2point(14);
                    let imageWidth = 54.75;
                    let imageHeight = 54.75;
                    let y = params.a6height + mm2point(4);
                    let x = (params.a6width - imageWidth) / 2;

                    //pdf.addImage(flag_seperator, x, y, imageWidth, imageHeight);

                    x = params.a6width / 2;
                    y += imageHeight + params.fontSize12 + mm2point(2);

                    setTextColorBlue();
                    pdf.setFontSize(params.fontSize14);
                    pdf.setFont('arial', 'bold');
                    let header = t('translation:pdfMemberPlaceholder');
                    header = pdf.splitTextToSize(header, lblLength);
                    pdf.text(header, x, y, { align: 'center', maxWidth: lblLength });
                    pdf.setFont('arial', 'normal');*/

                    let lblLength = params.a6width - params.paddingLeft - params.paddingRight - mm2point(14);
                    let y = params.a6height + mm2point(4);
                    let x = (params.a6width) / 2;

                    setTextColorBlue();
                    pdf.setFontSize(params.fontSize14);
                    pdf.setFont('arial', 'bold');
                    let header = t('translation:pdfMemberPlaceholder');
                    header = pdf.splitTextToSize(header, lblLength);
                    pdf.text(header, x, y, { align: 'center', maxWidth: lblLength });
                    pdf.setFont('arial', 'normal');









                    y += mm2point(40) + params.lineHeight9;
                    x = params.paddingLeft;
                    setTextColorBlack();
                    pdf.setFont('arial', 'normal');
                    pdf.setFontSize(params.fontSize8);
                    let infotext = t('translation:pdfInfoText');
                    infotext = pdf.splitTextToSize(infotext, params.a6width - params.paddingLeft - params.paddingRight);
                    justify(t('translation:pdfInfoText'), x, y, params.a6width - params.paddingLeft - params.paddingRight);
                    // pdf.text(infotext, x, y, { align: 'justify', maxWidth: params.a6width - params.paddingLeft - params.paddingRight });

                    y += mm2point(2) + params.lineHeight9 * infotext.length;
                    x = params.a6width / 2;
                    infotext = t('translation:pdfRelevantInformation');
                    infotext = pdf.splitTextToSize(infotext, lblLength);
                    pdf.text(infotext, x, y, { align: 'center', maxWidth: lblLength, isSymmetricSwapping: true });

                    y += mm2point(1) + params.lineHeight9 * infotext.length;
                    setTextColorBlue();
                    pdf.setFontSize(params.fontSize10);
                    infotext = t('translation:pdfInfoURL');
                    infotext = pdf.splitTextToSize(infotext, lblLength);
                    pdf.text(infotext, x, y, { align: 'center', maxWidth: lblLength });


                    setTextColorBlack();
                    pdf.setFont('arial', 'normal');
                }
            }

            setThirdPageIsReady(true);
        }
    }

    /**
     * Rotated Third page is the new second page on the A4.
     */
    const prepareThirdPageRotated = () => {
        if (pdf) {
            let lblLength = params.a6width - params.paddingRight - params.paddingRight - mm2point(14);
            let imageWidth = 225.75;
            let imageHeight = 4.75;
            let y = params.a6height - imageHeight * 2 - mm2point(4);
            let x = imageWidth + (params.a6width - imageWidth) / 2 + params.a6width;

            //pdf.addImage(flag_seperator, x, y, imageWidth, imageHeight, undefined, undefined, 180);

            x = params.a6width + params.a6width / 2;
            y += imageHeight - params.lineHeight12 - mm2point(2);

            setTextColorBlue();
            pdf.setFontSize(params.fontSize14);
            pdf.setFont('arial', 'bold');
            let header = t('translation:pdfMemberPlaceholder');
            header = pdf.splitTextToSize(header, lblLength);
            x = params.a6width * 2;
            y = centerSplittedText(header, x - 13, y);

            //escudo de El Salvador
            imageWidth = 70;
            imageHeight = 85;
            y -= imageHeight + mm2point(3);
            x = (params.a6width - imageWidth) / 2 + params.a6width;
            pdf.addImage(logo_goes_clean_alrevez, x - 13, y + 8, imageWidth, imageHeight);



            x = params.a6width * 2;
            y -= mm2point(30);

            setTextColorBlack();
            pdf.setFont('arial', 'normal');
            pdf.setFontSize(params.fontSize10);
            let infotext = t('translation:pdfInfoText');
            infotext = pdf.splitTextToSize(infotext, lblLength);
            justify(t('translation:pdfInfoText'),
                x - params.paddingRight - 13,
                y + 12,
                params.a6width - params.paddingLeft - params.paddingRight,
                true);
            y -= params.lineHeight12 * 2 + mm2point(2);

            infotext = t('translation:pdfRelevantInformation');
            infotext = pdf.splitTextToSize(infotext, lblLength);
            y = centerSplittedText(infotext, x - 13, y + 7) - params.lineHeight12;

            y += mm2point(3);
            setTextColorBlue();
            pdf.setFontSize(params.fontSize11);
            infotext = t('translation:pdfInfoURL');
            infotext = pdf.splitTextToSize(infotext, lblLength);
            y = centerSplittedText(infotext, x - 13, y);

            pdf.line(params.a6width + params.paddingLeft - 13, y - 2 + 8, params.a6width * 2 - params.paddingRight - 13, y - 2 + 8);

            y -= params.lineHeight9 + mm2point(4);
            setTextColorBlack();
            pdf.setFontSize(params.fontSize10);
            pdf.setFont('arial', 'italic');
            infotext = t('translation:pdfFoldingInstruction');
            infotext = pdf.splitTextToSize(infotext, lblLength);
            y = centerSplittedText(infotext, x - 13, y + 13);

            imageWidth = 173.25;
            imageHeight = 51;
            y -= imageHeight - mm2point(2);
            x = (params.a6width - imageWidth) / 2 + params.a6width;
            pdf.addImage(folding_instruction, x - 7, y, imageWidth, imageHeight);

            setTextColorBlack();
            pdf.setFont('arial', 'normal');
        }
    }

    const prepareFourthPageVaccination = () => {
        if (pdf && vaccinationSet && french) {

            for (let page = 1; page < 2; page++) {

                pdf.setPage(page);

                if (page === 1) {
                    prepareFourthPageVaccinationRotated();
                } else {

                    const lineHeight = params.lineHeight16;

                    let y = printCertificateHeader(t('translation:pdfHeaderVaccination'), french.translation.pdfHeaderVaccination);
                    y += params.space;

                    //For the labels on the left side
                    let xLeft = params.a6width + params.paddingInnerLeft;

                    pdf.setFontSize(params.fontSize10);

                    let disease = getValueSetDisplay(vaccinationSet.tg, diseaseAgentsData);
                    let lblDisease = t('translation:pdfDisease');
                    lblDisease = lblDisease.replace("diseaseName", disease ? disease : '');
                    let lblFrench = french.translation.pdfDisease;
                    lblFrench = lblFrench.replace("diseaseName", disease ? disease : '');

                    y = printDisease(xLeft, y, lineHeight, lblDisease, lblFrench);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfVaccineProphylaxis'),
                        french.translation.pdfVaccineProphylaxis,
                        getValueSetDisplay(vaccinationSet.vp, vaccines),
                        lineHeight, true);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfVaccineMedicalProduct'),
                        french.translation.pdfVaccineMedicalProduct,
                        getValueSetDisplay(vaccinationSet.mp, vacMedsData),
                        lineHeight, true);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfVaccineManufacturer'),
                        french.translation.pdfVaccineManufacturer,
                        getValueSetDisplay(vaccinationSet.ma, vaccineManufacturers),
                        lineHeight, true);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfNumberOfDoses'),
                        french.translation.pdfNumberOfDoses,
                        vaccinationSet.dn.toString() + ' / ' + vaccinationSet.sd.toString(),
                        lineHeight, true);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfDateOfVaccination'),
                        french.translation.pdfDateOfVaccination,
                        vaccinationSet.dt,
                        lineHeight, true);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfMemberStateOfVaccination'),
                        french.translation.pdfMemberStateOfVaccination,
                        getValueSetDisplay(vaccinationSet.co, countryCodeValueSet),
                        lineHeight, true);

                    printHorizontalBlock(xLeft, y,
                        t('translation:pdfCertificateIssuer'),
                        french.translation.pdfCertificateIssuer,
                        vaccinationSet.is,
                        lineHeight, true);
                }

                setFourthPageIsReady(true);
            }
        }
    }

    const printDisease = (x: number, y: number, lineHeight: number, value: string, lblFrench: string): number => {
        if (pdf && french) {
            pdf.setFont('arial', 'bold');
            value = pdf.splitTextToSize(value, params.a6width);
            pdf.text(value, x, y);

            y += lineHeight * value.length + mm2point(1);

            //pdf.setFont('arial', 'italic');
            //lblFrench = pdf.splitTextToSize(lblFrench, params.a6width);
            //pdf.text(lblFrench, x, y);

            //y += lineHeight * lblFrench.length + mm2point(2);            
        }

        return y;
    }

    const printDiseaseRotated = (x: number, y: number, lineHeight: number, value: string, lblFrench: string): number => {
        if (pdf && french) {
            pdf.setFont('arial', 'bold');
            value = pdf.splitTextToSize(value, params.a6width - 38);
            pdf.text(value, x, y, { align: 'left', angle: 180 });

            y -= lineHeight * value.length + mm2point(1);

            pdf.setFont('arial', 'italic');

            lblFrench = pdf.splitTextToSize(lblFrench, params.a6width);
            pdf.text(lblFrench, x, y, { align: 'left', angle: 180 });

            y -= lineHeight * lblFrench.length + 6;
        }

        return y;
    }

    const prepareFourthPageVaccinationRotated = () => {
        if (pdf && vaccinationSet && french) {
            const lineHeight = params.lineHeight16;

            //let y = printCertificateHeaderRotated(t('translation:pdfHeaderVaccination'), french.translation.pdfHeaderVaccination);
            let y = printCertificateHeaderRotated(t('translation:pdfHeaderVaccination'), '');
            y += params.space;

            //For the labels on the left side
            let xLeft = params.a6width - params.paddingInnerLeft - 15;

            pdf.setFontSize(params.fontSize10);

            let disease = getValueSetDisplay(vaccinationSet.tg, diseaseAgentsData);
            let lblDisease = t('translation:pdfDisease');
            /*lblDisease = lblDisease.replace("diseaseName", disease ? disease : '');
            let lblFrench = french.translation.pdfDisease;
            lblFrench = lblFrench.replace("diseaseName", disease ? disease : '');*/

            /*y = printDiseaseRotated(xLeft, y,
                lineHeight,
                lblDisease,
                lblFrench);*/

            y = printDiseaseRotated(xLeft, y,
                lineHeight,
                lblDisease,
                '');

            y = printHorizontalBlockRotated(xLeft, y + 10,
                t('translation:pdfVaccineProphylaxis'),
                french.translation.pdfVaccineProphylaxis,
                getValueSetDisplay(vaccinationSet.vp, vaccines),
                lineHeight, true);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfVaccineMedicalProduct'),
                french.translation.pdfVaccineMedicalProduct,
                getValueSetDisplay(vaccinationSet.mp, vacMedsData),
                lineHeight, true);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfVaccineManufacturer'),
                french.translation.pdfVaccineManufacturer,
                getValueSetDisplay(vaccinationSet.ma, vaccineManufacturers),
                lineHeight, true);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfNumberOfDoses'),
                french.translation.pdfNumberOfDoses,
                vaccinationSet.dn.toString() + ' / ' + vaccinationSet.sd.toString(),
                lineHeight, true);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfDateOfVaccination'),
                french.translation.pdfDateOfVaccination,
                vaccinationSet.dt,
                lineHeight, true);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfMemberStateOfVaccination'),
                french.translation.pdfMemberStateOfVaccination,
                getValueSetDisplay(vaccinationSet.co, countryCodeValueSet),
                lineHeight, true);

            printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfCertificateIssuer'),
                french.translation.pdfCertificateIssuer,
                vaccinationSet.is,
                lineHeight, true);
        }
    }

    const prepareFourthPageTest = () => {
        if (pdf && testSet && french) {
            for (let page = 1; page < 3; page++) {

                pdf.setPage(page);

                if (page === 1) {
                    prepareFourthPageTestRotated();
                } else {

                    const lineHeight = params.lineHeight9;

                    //let y = printCertificateHeaderRotated(t('translation:pdfHeaderTest'), french.translation.pdfHeaderTest);
                    //let y = printCertificateHeaderRotated(t('translation:pdfHeaderTest'), '');

                    let x = params.a6width + params.paddingInnerLeft;

                    pdf.setFontSize(params.fontSize9);

                    let disease = getValueSetDisplay(testSet.tg, diseaseAgentsData);
                    let lblDisease = t('translation:pdfDisease');
                    lblDisease = lblDisease.replace("diseaseName", disease ? disease : '');
                    let lblFrench = french.translation.pdfDiseaseRecovery;
                    lblFrench = lblFrench.replace("diseaseName", disease ? disease : '');

                    //y = printDisease(x, y, lineHeight, lblDisease, lblFrench);
                    //y = printDisease(x, y, lineHeight, lblDisease, '');

                    /*y = printHorizontalBlock(x, y,
                        t('translation:pdfTypeOfTest'),
                        french.translation.pdfTypeOfTest,
                        getValueSetDisplay(testSet.tt, testTypeValueSet),
                        lineHeight, true);

                    y = printHorizontalBlock(x, y,
                        t('translation:pdfTestName'),
                        french.translation.pdfTestName,
                        testSet.nm,
                        lineHeight, true);

                    y = printHorizontalBlock(x, y,
                        t('translation:pdfTestManufacturer'),
                        french.translation.pdfTestManufacturer,
                        getValueSetDisplay(testSet.ma, testManufacturersValueSet),
                        lineHeight, true);

                    y = printHorizontalBlock(x, y,
                        t('translation:pdfDateSampleCollection'),
                        french.translation.pdfDateSampleCollection,
                        utils.convertDateToOutputFormat(testSet.sc),
                        lineHeight, true);

                    y = printHorizontalBlock(x, y,
                        t('translation:pdfTestResult'),
                        french.translation.pdfTestResult,
                        getValueSetDisplay(testSet.tr, testResultValueSet),
                        lineHeight, true);

                    y = printHorizontalBlock(x, y,
                        t('translation:pdfTestingCentre'),
                        french.translation.pdfTestingCentre,
                        testSet.tc,
                        lineHeight, true);

                    y = printHorizontalBlock(x, y,
                        t('translation:pdfStateOfVaccination'),
                        french.translation.pdfStateOfVaccination,
                        getValueSetDisplay(testSet.co, countryCodeValueSet),
                        lineHeight, true);

                    printHorizontalBlock(x, y,
                        t('translation:pdfCertificateIssuer'),
                        french.translation.pdfCertificateIssuer,
                        testSet.is,
                        lineHeight, true);*/

                    setFourthPageIsReady(true);
                }
            }
        }

    }

    const prepareFourthPageTestRotated = () => {
        if (pdf && testSet && french) {
            const lineHeight = params.lineHeight10;

            //let y = printCertificateHeaderRotated(t('translation:pdfHeaderTest'), french.translation.pdfHeaderTest);
            let y = printCertificateHeaderRotated(t('translation:pdfHeaderTest'), '');
            let x = params.a6width - params.paddingInnerLeft;

            pdf.setFontSize(params.fontSize10);

            let disease = getValueSetDisplay(testSet.tg, diseaseAgentsData);
            let lblDisease = t('translation:pdfDiseaseRecovery');
            lblDisease = lblDisease.replace("diseaseName", disease ? disease : '');
            /*let lblFrench = french.translation.pdfDiseaseRecovery;
            lblFrench = lblFrench.replace("diseaseName", disease ? disease : '');

            y = printDiseaseRotated(x, y,
                lineHeight,
                lblDisease,
                lblFrench);*/

            y = printDiseaseRotated(x, y,
                lineHeight,
                lblDisease,
                '');

            y = printHorizontalBlockRotated(x, y,
                t('translation:pdfTypeOfTest'),
                french.translation.pdfTypeOfTest,
                getValueSetDisplay(testSet.tt, testTypeValueSet),
                lineHeight, true);

            y = printHorizontalBlockRotated(x, y,
                t('translation:pdfTestName'),
                french.translation.pdfTestName,
                testSet.nm ? testSet.nm : ' ',
                lineHeight, true);

            y = printHorizontalBlockRotated(x, y,
                t('translation:pdfTestManufacturer'),
                french.translation.pdfTestManufacturer,
                getValueSetDisplay(testSet.ma, testManufacturersValueSet),
                lineHeight, true);

            y = printHorizontalBlockRotated(x, y,
                t('translation:pdfDateSampleCollection'),
                french.translation.pdfDateSampleCollection,
                utils.convertDateToOutputFormat(testSet.sc),
                lineHeight, true);

            y = printHorizontalBlockRotated(x, y,
                t('translation:pdfTestResult'),
                french.translation.pdfTestResult,
                getValueSetDisplay(testSet.tr, testResultValueSet),
                lineHeight, true);

            y = printHorizontalBlockRotated(x, y,
                t('translation:pdfTestingCentre'),
                french.translation.pdfTestingCentre,
                testSet.tc,
                lineHeight, true);

            y = printHorizontalBlockRotated(x, y,
                t('translation:pdfStateOfVaccination'),
                french.translation.pdfStateOfVaccination,
                getValueSetDisplay(testSet.co, countryCodeValueSet),
                lineHeight, true);

            printHorizontalBlockRotated(x, y,
                t('translation:pdfCertificateIssuer'),
                french.translation.pdfCertificateIssuer,
                testSet.is,
                lineHeight, true);
        }
    }

    const prepareFourthPageRecovery = () => {
        if (pdf && recoverySet && french) {

            for (let page = 1; page < 3; page++) {

                pdf.setPage(page);

                if (page === 1) {
                    prepareFourthPageRecoveryRotated();
                }
                else {
                    const lineHeight = params.lineHeight10;

                    let y = printCertificateHeader(t('translation:pdfHeaderRecovery'), french.translation.pdfHeaderRecovery, params.paddingTop);
                    y += params.smallHeaderLineHeight + params.space;

                    //For the labels on the left side
                    let xLeft = params.a6width + params.paddingInnerLeft;

                    pdf.setFontSize(params.fontSize10);

                    let disease = getValueSetDisplay(recoverySet.tg, diseaseAgentsData);
                    let lblDisease = t('translation:pdfDiseaseRecovery');
                    lblDisease = lblDisease.replace("diseaseName", disease ? disease : '');
                    let lblFrench = french.translation.pdfDiseaseRecovery;
                    lblFrench = lblFrench.replace("diseaseName", disease ? disease : '');

                    y = printDisease(xLeft, y, lineHeight, lblDisease, lblFrench);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfDatePositiveTestResult'),
                        french.translation.pdfDatePositiveTestResult,
                        recoverySet.fr);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfStateOfTest'),
                        french.translation.pdfStateOfTest,
                        getValueSetDisplay(recoverySet.co, countryCodeValueSet),
                        lineHeight, true);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfCertificateIssuer'),
                        french.translation.pdfCertificateIssuer,
                        recoverySet.is,
                        lineHeight, true);

                    y = printHorizontalBlock(xLeft, y,
                        t('translation:pdfValidFrom'),
                        french.translation.pdfValidFrom,
                        recoverySet.df,
                        lineHeight, true);

                    printHorizontalBlock(xLeft, y,
                        t('translation:pdfValidTo'),
                        french.translation.pdfValidTo,
                        recoverySet.du,
                        lineHeight, true);

                    setFourthPageIsReady(true);
                }
            }
        }
    }

    /**
     * Rotated Fourth page is the new first page on the A4.
     */
    const prepareFourthPageRecoveryRotated = () => {
        if (pdf && recoverySet && french) {

            const lineHeight = params.lineHeight10;

            //let y = printCertificateHeaderRotated(t('translation:pdfHeaderRecovery'), french.translation.pdfHeaderRecovery, params.paddingTop);
            let y = printCertificateHeaderRotated(t('translation:pdfHeaderRecovery'), '', params.paddingTop);
            y -= params.smallHeaderLineHeight - params.space;

            //For the labels on the left side
            let xLeft = params.a6width - params.paddingInnerLeft;

            pdf.setFontSize(params.fontSize10);

            let disease = getValueSetDisplay(recoverySet.tg, diseaseAgentsData);
            let lblDisease = t('translation:pdfDiseaseRecovery');
            lblDisease = lblDisease.replace("diseaseName", disease ? disease : '');
            /*let lblFrench = french.translation.pdfDiseaseRecovery;
            lblFrench = lblFrench.replace("diseaseName", disease ? disease : '');

            y = printDiseaseRotated(xLeft, y,
                lineHeight,
                lblDisease,
                lblFrench);*/
            y = printDiseaseRotated(xLeft, y,
                lineHeight,
                lblDisease,
                '');

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfDatePositiveTestResult'),
                french.translation.pdfDatePositiveTestResult,
                recoverySet.fr);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfStateOfTest'),
                french.translation.pdfStateOfTest,
                getValueSetDisplay(recoverySet.co, countryCodeValueSet),
                lineHeight, true);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfCertificateIssuer'),
                french.translation.pdfCertificateIssuer,
                recoverySet.is,
                lineHeight, true);

            y = printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfValidFrom'),
                french.translation.pdfValidFrom,
                recoverySet.df,
                lineHeight, true);

            printHorizontalBlockRotated(xLeft, y,
                t('translation:pdfValidTo'),
                french.translation.pdfValidTo,
                recoverySet.du,
                lineHeight, true);
        }
    }

    const printHorizontalBlock = (x: number, y: number, lbl: any, lblFrench: any, value?: string, lineHeight?: number, isItalic?: boolean): number => {
        let result = y;
        lineHeight = lineHeight ? lineHeight : params.lineHeight;

        if (pdf) {
            pdf.setFont('arial', 'bold');
            lbl = pdf.splitTextToSize(lbl, lblLength);
            pdf.text(lbl, x, y);

            y += lineHeight * lbl.length;

            pdf.setFont('arial', 'italic');

            const frenchText = pdf.splitTextToSize(lblFrench, lblLength);
            pdf.text(frenchText, x, y);
            y += lineHeight * frenchText.length;

            if (value) {
                pdf.setFont('arial', 'normal');
                const valueText = pdf.splitTextToSize(value, lblLength);
                pdf.text(valueText, x, y);

                y += lineHeight * valueText.length;
            }

            result = y + mm2point(2);
        }

        return result;
    }

    // const printAsTableRow = (x: number, y: number, lbl: any, lblFrench: any, value?: string, lineHeight?: number, isItalic?: boolean): number => {
    //     let result = y;
    //     let lblRightLength = params.a6width / 4;
    //     let lblLeftLength = lblRightLength * 2 + mm2point(12);
    //     let xRight = x + lblLeftLength + mm2point(2);
    //     lineHeight = lineHeight ? lineHeight : params.lineHeight;

    //     if (pdf) {
    //         pdf.setFont('arial', 'bold');
    //         lbl = pdf.splitTextToSize(lbl, lblLeftLength);
    //         pdf.text(lbl, x, y);

    //         if (value) {
    //             // pdf.setFont('arial', 'normal');
    //             value = pdf.splitTextToSize(value, lblRightLength);
    //             pdf.text(value!, xRight, y, { align: 'left' });
    //         }

    //         if (value) {
    //             y += lineHeight * (lbl.length > value.length ? lbl.length : value.length / 1.35);
    //         } else {
    //             y += lineHeight * lbl.length;
    //         }

    //         pdf.setFont('arial', 'italic');

    //         const frenchText = pdf.splitTextToSize(lblFrench, lblLeftLength);
    //         pdf.text(frenchText, x, result + lineHeight * lbl.length);
    //         y += lineHeight * frenchText.length;

    //         result = y + mm2point(2);
    //     }

    //     return result;
    // }

    const printHorizontalBlockPerson = (x: number, y: number, lbl: any, lblFrench: any, value?: string): number => {
        let result = y;

        if (value && pdf) {
            pdf.setFontSize(params.fontSize11)
            pdf.setFont('arial', 'bold');
            lbl = pdf.splitTextToSize(lbl, lblLength);
            pdf.text(lbl, x, y);
            y += params.lineHeight12 * lbl.length;

            pdf.setFontSize(params.fontSize10)
            pdf.setFont('arial', 'italic');

            const frenchText = pdf.splitTextToSize(lblFrench, lblLength);
            pdf.text(frenchText, x, y);
            y += params.lineHeight12 * frenchText.length;

            pdf.setFontSize(params.fontSize11);
            pdf.setFont('arial', 'normal');
            setTextColorBlue()
            const valueText = pdf.splitTextToSize(value, lblLength);
            pdf.text(valueText, x, y);
            setTextColorBlack()

            result = y + params.lineHeight11 * valueText.length + mm2point(2);
        }

        return result;
    }

    const printHorizontalBlockRotated = (x: number, y: number, lbl: any, lblFrench: any, value?: string, lineHeight?: number, isItalic?: boolean): number => {
        let result = y;
        lineHeight = lineHeight ? lineHeight : params.lineHeight;

        if (value && pdf) {
            pdf.setFont('arial', 'normal');
            lbl = pdf.splitTextToSize(lbl, lblLength - 20);
            y = leftSplittedTextRotated(lbl, x, y);

            if (isItalic) {
                pdf.setFont('arial', 'italic');
            } else {
                pdf.setFont('arial', 'normal');
            }
            //const frenchText = pdf.splitTextToSize(lblFrench, lblLength);
            //y = leftSplittedTextRotated(frenchText, x, y);

            pdf.setFont('arial', 'bold');
            const valueText = pdf.splitTextToSize(value, lblLength - 20);
            y = leftSplittedTextRotated(valueText, x, y);

            result = y - 6;
        }

        return result;
    }

    const printHorizontalBlockRotatedMIO = (x: number, y: number, lbl: any, lblFrench: any, value?: string, lineHeight?: number, isItalic?: boolean): number => {
        let result = y;
        lineHeight = lineHeight ? lineHeight : params.lineHeight;

        if (value && pdf) {
            pdf.setFont('arial', 'normal');
            lbl = pdf.splitTextToSize(lbl, lblLength);
            y = leftSplittedTextRotated(lbl, x, y);

            pdf.setFont('arial', 'bold');
            const valueText = pdf.splitTextToSize(value, lblLength);
            y = leftSplittedTextRotated(valueText, x, y);

            result = y - 6;
        }

        return result;
    }

    // const printAsTableRowRotated = (x: number, y: number, lbl: any, lblFrench: any, value?: string, lineHeight?: number, isItalic?: boolean): number => {
    //     let result = y;
    //     let lblRightLength = params.a6width / 4;
    //     let xLeft = params.paddingLeft + lblRightLength - mm2point(2);
    //     let lblLeftLength = lblRightLength * 2 + mm2point(12);
    //     lineHeight = lineHeight ? lineHeight : params.lineHeight;
    //     let tmpPosition = 0;

    //     if (value && pdf) {
    //         pdf.setFont('arial', 'bold');
    //         lbl = pdf.splitTextToSize(lbl, lblLeftLength);
    //         y = leftSplittedTextRotated(lbl, x, y);

    //         if (value) {
    //             //pdf.setFont('arial', 'normal');
    //             value = pdf.splitTextToSize(value, lblRightLength);
    //             tmpPosition = leftSplittedTextRotated(value!, xLeft, result);
    //             if (y > tmpPosition) {
    //                 y = tmpPosition;
    //             }
    //         }

    //         if (isItalic) {
    //             pdf.setFont('arial', 'italic');
    //         } else {
    //             pdf.setFont('arial', 'normal');
    //         }
    //         const frenchText = pdf.splitTextToSize(lblFrench, lblLeftLength);
    //         tmpPosition = leftSplittedTextRotated(frenchText, x, result - lineHeight * lbl.length);
    //         if (y > tmpPosition) {
    //             y = tmpPosition;
    //         }

    //         result = y - 3;
    //     }

    //     return result;
    // }

    const printCertificateHeader = (header: any, frenchHeader: string, paddingTop?: number): number => {
        let result = 0;

        if (pdf) {
            let x = params.a6width + params.a6width / 2;
            let y = params.a6height;
            const lblLength = params.a6width - params.paddingLeft - params.paddingRight;

            pdf.setFont('arial', 'bold');
            pdf.setFontSize(params.smallHeaderFontSize);
            setTextColorBlue();

            paddingTop = paddingTop ? paddingTop : 0;
            y += params.smallHeaderLineHeight + paddingTop;

            header = pdf.splitTextToSize(header, params.a6width);
            pdf.text(header, x, y, { align: 'center', maxWidth: lblLength });

            y += params.smallHeaderLineHeight + header.length;
            pdf.setFontSize(params.fontSize14);
            frenchHeader = pdf.splitTextToSize(frenchHeader, params.a6width);
            pdf.text(frenchHeader, x, y, { align: 'center', maxWidth: lblLength });
            setTextColorBlack();

            result = y + params.smallHeaderLineHeight * frenchHeader.length;
        }

        return result;
    }

    const printCertificateHeaderRotated = (header: any, frenchHeader: string, paddingTop?: number): number => {
        let result = 0;

        if (pdf) {
            let x = params.a6width
            let y = params.a6height;

            pdf.setFont('arial', 'bold');
            pdf.setFontSize(params.smallHeaderFontSize);
            setTextColorBlue();

            paddingTop = paddingTop ? paddingTop : 20;
            y -= params.smallHeaderLineHeight + paddingTop;

            header = pdf.splitTextToSize(header, lblLength);
            centerSplittedText(header, x, y);

            y -= params.smallHeaderLineHeight;
            pdf.setFontSize(params.fontSize14);
            frenchHeader = pdf.splitTextToSize(frenchHeader, lblLength);
            centerSplittedText(frenchHeader, x, y);
            setTextColorBlack();

            result = y - params.smallHeaderLineHeight * frenchHeader.length;
        }

        return result;
    }

    const centerSplittedText = (txt: string, x: number, y: number): number => {
        let result = y;

        if (pdf) {
            const offset = x;
            for (let i = 0; i < txt.length; i++) {
                const dim = pdf.getTextDimensions(txt[i]);
                x = offset - (params.a6width - dim.w) / 2;
                pdf.text(txt[i], x, y, { align: 'left', angle: 180 });
                y -= dim.h + 2;
            }

            result = y;
        }
        return result;
    }

    const leftSplittedTextRotated = (text: string, x: number, y: number): number => {
        let result = y;

        if (pdf) {
            for (let i = 0; i < text.length; i++) {
                const dim = pdf.getTextDimensions(text[i]);
                pdf.text(text[i], x, y, { align: 'left', angle: 180 });
                y -= dim.h;
            }
            result = y;
        }
        return result;
    }

    const setTextColorBlue = () => {
        pdf!.setTextColor(0, 51, 153);
    }

    const setTextColorBlack = () => {
        pdf!.setTextColor(0, 0, 0);
    }

    const setTextColorWhite = () => {
        pdf!.setTextColor(256, 256, 256);
    }

    const justify = (text: string, xStart: number, yStart: number, textWidth: number, rotation?: boolean) => {
        if (pdf) {
            rotation = rotation ? rotation : false;
            text = text.replace(/(?:\r\n|\r|\n)/g, ' ');
            text = text.replace(/ +(?= )/g, '');
            const lineHeight = pdf.getTextDimensions('a').h * 1.15;
            const words = text.split(' ');
            let lineNumber = 0;
            let wordsInfo: IWordInfo[] = [];
            let lineLength = 0;
            let txtLines: Array<IWordInfo[]> = [];
            for (const word of words) {
                const wordLength = pdf.getTextWidth(word + ' ');
                if (wordLength + lineLength > textWidth) {
                    if (!rotation) {
                        writeLine(pdf, wordsInfo, lineLength, lineNumber++, xStart, yStart, lineHeight, textWidth);
                    } else {
                        let words: IWordInfo[] = [...wordsInfo]
                        txtLines.push(words);
                    }
                    wordsInfo = [];
                    lineLength = 0;
                }
                wordsInfo.push({ text: word, wordLength });
                lineLength += wordLength;
            }
            if (wordsInfo.length > 0) {
                if (!rotation) {
                    writeLastLine(wordsInfo, pdf, yStart, lineNumber, lineHeight, textWidth);
                } else {
                    txtLines.push(wordsInfo);
                    for (let i = txtLines.length - 1; i >= 0; --i) {
                        if (i === txtLines.length - 1) {
                            writeLastLineRotated(txtLines[i], pdf, yStart, lineHeight, textWidth);
                        } else {
                            writeLineRotated(pdf, txtLines[i], lineNumber++, xStart, yStart, lineHeight, textWidth);
                        }
                    }
                }
            }
        }
    }

    const writeLastLine = (wordsInfo: IWordInfo[], pdfGen: jsPDF, yStart: number, lineNumber: number, lineHeight: number, textWidth: number) => {
        const line = wordsInfo.map(x => x.text).join(' ');
        let xStart = params.paddingLeft + (textWidth / 2);
        pdfGen.text(line, xStart, yStart + lineNumber * lineHeight, { align: 'center' });
    }

    const writeLastLineRotated = (wordsInfo: IWordInfo[], pdfGen: jsPDF, yStart: number, lineHeight: number, textWidth: number) => {
        let line = wordsInfo.map(x => x.text).join(' ');
        let xStart = params.a6width * 2;
        line = pdfGen.splitTextToSize(line, textWidth);
        centerSplittedText(line, xStart, yStart - lineHeight);
    }

    const writeLine = (pdfGen: jsPDF, wordsInfo: IWordInfo[], lineLength: number, lineNumber: number, xStart: number, yStart: number, lineHeight: number, textWidth: number) => {
        const wordSpacing = (textWidth - lineLength) / (wordsInfo.length - 1);
        let x = xStart;
        const y = yStart + lineNumber * lineHeight;
        for (const wordInfo of wordsInfo) {
            pdfGen.text(wordInfo.text, x, y);
            x += wordInfo.wordLength + wordSpacing;
        }
    }

    const writeLineRotated = (pdfGen: jsPDF, wordsInfo: IWordInfo[], lineNumber: number, xStart: number, yStart: number, lineHeight: number, textWidth: number) => {
        let lineLength = 0;
        for (const wordInfo of wordsInfo) {
            lineLength += wordInfo.wordLength;
        }

        const wordSpacing = (textWidth - lineLength) / (wordsInfo.length - 1);
        let x = xStart;
        const y = yStart + lineNumber * lineHeight;
        for (const wordInfo of wordsInfo) {
            pdfGen.text(wordInfo.text, x, y, { align: 'left', angle: 180 });
            x -= wordInfo.wordLength + wordSpacing;
        }
    }

    return pdf;
}

export default usePdfGenerator;



