/*
 * eu-digital-green-certificates/ dgca-issuance-web
 *
 * (C) 2021, T-Systems International GmbH
 *
 * Deutsche Telekom AG and all other contributors /
 * copyright owners license this file to you under the Apache
 * License, Version 2.0 (the "License"); you may not use this
 * file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { Button, Container, Fade, Form, Card, CardImg, Spinner, Modal, ModalBody } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ModalComponent from './modal/moda.component';

import HelpCircle from '../assets/images/help_outline_black_24dp.svg';
import ErrorIcon from '../assets/images/error_icon.svg';
import '../i18n';

import React from 'react';
import SpinnerHere from './spinner/spinner.component';
import AppContext from '../misc/appContext';
import axios from "axios";
import vacuna_covid from '../assets/images/vacuna.svg'
import InputMask from 'react-input-mask';
import es from 'date-fns/locale/es';

//const APIURL = process.env.REACT_APP_API_VALIDADOR;
const APIURL = 'https://api-validador.covid19.gob.sv';

const minsalApi = axios.create({
    baseURL: APIURL,
    headers: {
        "Content-Type": "application/json"
    },
})


const LandingPage = (props: any) => {
    const context = React.useContext(AppContext);

    const [isInit, setIsInit] = React.useState(false)
    const [dui, setDui] = React.useState('')
    const [error, setError] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const [fechaNacimiento, setFechaNacimiento] = React.useState<Date>();
    const [tipoDoc, setTipoDoc] = React.useState('DUI');

    React.useEffect(() => {
        if (context.navigation)
            setIsInit(true);
    }, [context.navigation])

    const handleFechaNacimiento = (evt: Date | [Date, Date] | null) => {
        const date = handleDateChange(evt);
        setFechaNacimiento(date);

    }

    const handleDateChange = (evt: Date | [Date, Date] | null) => {
        let date: Date;

        if (Array.isArray(evt))
            date = evt[0];
        else
            date = evt as Date;

        if (date) {
            date.setHours(12);
        }

        return date;
    }
    // @ts-ignore: Unreachable code error
    const isDui = (dui) => {
        if (dui === null) return false
        let valido = false;

        // Validando la longitud del numero de DUI
        if (dui.length === 10) {

            // Validando que no sea ceros todos los dígitos
            if (dui !== '00000000-0') {

                // Obteniendo los dígitos y el verificador
                let [digitos, validador] = dui.split('-');

                // Verficiando que la cadena
                if (typeof digitos !== 'undefined' && typeof validador !== 'undefined') {

                    // Verificando que el validador sea de un solo caracter
                    if (validador.length === 1) {

                        // Convirtiendo los digitos a array
                        digitos = digitos.split('');

                        // Convirtiendo los datos a tipo integer
                        validador = parseInt(validador, 10);
                        // @ts-ignore: Unreachable code error
                        digitos = digitos.map(digito => parseInt(digito, 10));
                        // @ts-ignore: Unreachable code error
                        let suma = digitos.reduce((total, digito, index) => total += (digito * (9 - index)), 0);

                        // Obteniendo el Modulo base 10
                        let mod = suma % 10;
                        mod = (validador === 0 && mod === 0) ? 10 : mod;

                        let resta = 10 - mod;

                        if (resta === validador) {
                            valido = true;
                        }
                    }
                }
            }
        }
        return valido;
    }


    const validar = () => {
        if (!dui) {
            setError(true)
            setMessage('DUI es requerido')
            return false
        }

        if (tipoDoc == "DUI") {
            if (!isDui(dui)) {
                setError(true)
                setMessage('ID no es valido')
                return false
            }
        }


        if (!fechaNacimiento) {
            setError(true)
            setMessage('Fecha de nacimiento es requerido')
            return false
        }

        setError(false)
        setMessage('')
        return true
    }

    //bandera para mostrar el modal
    const [show, setShow] = React.useState(false);
    //tomara el texto para mostrar en el modal
    const [textModal, setTextModal] = React.useState<any>();

    //funcion para abrir el modal en el simbolo de pregunta
    const handleOpenModalInfo = () => {
        setTextModal(<>El código de vacunación del menor se encuentra en la cartilla de vacunación. Si ha perdido la cartilla de vacunación, <a href="https://vacunacioncovid19.gob.sv/" target="_blank">consulte el código aquí</a> , en la opción “Menor de edad”, y en “Registro por primera vez”.</>)
        setShow(true)
    }

    // @ts-ignore: Unreachable code error
    const getInfo = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!validar()) return
        try {
            setLoading(true)
            const response = await minsalApi.get(`/api/v1/info-certificado?identificador_persona=${dui}&fecha_nac=${moment(fechaNacimiento).format('L')}`).then((response) => {
                return response;
            }).catch((error) => {
                // Error
                if (error.response) {
                    if (error.response.status === 404) {
                        //validamos si se habia escogido menor
                        //if (tipoDoc === "Menor") {
                        setError(true)
                        setMessage('Datos no encontrados');
                        // } else {
                        //     setError(true)
                        //     setMessage('Información incorrecta');
                        // }
                    } else if (error.response.status === 500) {
                        setError(true)
                        setMessage('Datos no encontrados');
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                return error;
            });
            props.setEudgc(response.data.persona)
            props.setCertificado(response.data.certificado)
            context.navigation!.toShowCert()

        } catch (e) {
            // @ts-ignore: Unreachable code error
            if (e.response && (e.response.status === 404 || e.response.status === 400)) {
                setError(true)
                // @ts-ignore: Unreachable code error
                setMessage(e.response.data.message)
            }
        } finally {
            setLoading(false)
        }
    }

    return (!isInit ? <SpinnerHere /> :
        <Fade appear={true} in={true} className="content-w p-0 mx-auto">
            <Container className='center-content p-0 content-w'>
                {!loading &&
                    <Card body className="text-center card-certificado content-w">
                        <CardImg variant="top" src={vacuna_covid} className="vacunaLogo" />
                        <Card.Title className="sub-title-vacuna mb-4 text300">Obtén tu certificado digital completando los
                            siguientes campos:</Card.Title>
                        <Form onSubmit={getInfo}>
                            <div className="mb-4">
                                <Form.Group>
                                    <Form.Label className="label-input text300">Seleccione una opción</Form.Label>
                                    <select className='form-control w-100' name="tipoDoc" value={tipoDoc}
                                        onChange={e => {
                                            setTipoDoc(e.target.value)
                                        }} >
                                        <option value="Menor">Menor de edad</option>
                                        <option value="Residente">Residente/Extranjero</option>
                                        <option value="DUI">DUI</option>
                                    </select>
                                </Form.Group>
                                <Form.Group controlId='formLastDateInput' className='form-group pb-3 mb-0'>
                                    <Form.Label className="label-input text300">
                                        {
                                            tipoDoc == "DUI" ? "Número de DUI"
                                                : (tipoDoc == "Menor" ? <><span>Código de vacunación del menor </span> <span
                                                    onClick={() => { handleOpenModalInfo() }}><img src={HelpCircle} />
                                                </span></> : "Número de identificación")
                                        }
                                    </Form.Label>
                                    {/*<Form.Control type="text" placeholder="0000000-0" value={dui}
                                                      onChange={(evt: any) => {
                                                          if (evt.target.value) setDui(evt.target.value);
                                                          // @ts-ignore: Unreachable code error
                                                          else setDui('')
                                                      }}/>*/}
                                    {
                                        tipoDoc === "DUI" && (
                                            <InputMask mask="99999999-9" type="tel" required placeholder="00000000-0" className="form-control w-100" value={dui}
                                                onChange={(evt: any) => {
                                                    if (evt.target.value) setDui(evt.target.value);
                                                    // @ts-ignore: Unreachable code error
                                                    else setDui('')
                                                }} />
                                        )
                                    }
                                    {
                                        (tipoDoc === "Menor" || tipoDoc === "Residente") && (
                                            <input className='form-control w-100' value={dui}
                                                onChange={(evt: any) => {
                                                    if (evt.target.value) setDui(evt.target.value);
                                                    // @ts-ignore: Unreachable code error
                                                    else setDui('')
                                                }} />
                                        )
                                    }

                                </Form.Group>
                                <Form.Group controlId='formLastDateInput' className='form-group pb-3 mb-0'>
                                    <Form.Label
                                        className='label-input text300'>Fecha de nacimiento</Form.Label>


                                    <DatePicker
                                        selected={fechaNacimiento}
                                        onChange={handleFechaNacimiento}
                                        dateFormat='dd-MM-yyyy'
                                        isClearable
                                        placeholderText="DD-MM-AAAA"
                                        className='form-control w-100'
                                        wrapperClassName='align-self-center'
                                        locale={es}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        required
                                        customInput={<InputMask mask="99-99-9999" type="tel" />}
                                    />
                                    {error &&
                                        <span className="input-error"><img src={ErrorIcon} /> {message}</span>
                                    }
                                </Form.Group>
                            </div>
                            <Button variant="primary" type="submit" className="button-certificado mb-3">
                                Generar
                            </Button>
                        </Form>
                    </Card>
                }
                {
                    loading &&
                    < div className="d-flex justify-content-center align-items-center flex-column h-75"
                        style={{ gap: '3rem' }}>
                        <Spinner animation="border" as="div" variant="light" />
                        <p className="text-white font-weight-bold" style={{ fontSize: '1.25rem' }}>Procesando tus
                            datos...</p>
                    </div>
                }
                {show && (
                    <ModalComponent
                        setShow={setShow}
                        text={textModal}
                    />
                )}
            </Container>
        </Fade>
    )
}

export default LandingPage;
