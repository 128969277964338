/*
 * eu-digital-green-certificates/ dgca-issuance-web
 *
 * (C) 2021, T-Systems International GmbH
 *
 * Deutsche Telekom AG and all other contributors /
 * copyright owners license this file to you under the Apache
 * License, Version 2.0 (the "License"); you may not use this
 * file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Button, Card, Col, Container, Fade, Row, Spinner } from 'react-bootstrap'

import '../i18n';
import { useTranslation } from 'react-i18next';

import QRCode from 'qrcode.react';

import SpinnerHere from './spinner/spinner.component';
import { EUDCC1 } from '../generated-files/dgc-combined-schema';
import genEDGCQR, { CertResult } from '../misc/edgcQRGenerator';

import ShowCertificateData from './modules/show-certificate-data.component';
import usePdfGenerator from '../misc/usePdfGenerator';
import AppContext from '../misc/appContext';
import { IValueSetList } from '../misc/useValueSet';


const ShowCertificate = (props: any) => {
    const context = React.useContext(AppContext);
    const { t } = useTranslation();

    const [isInit, setIsInit] = React.useState(false)
    const [pdfIsInit, setPdfIsInit] = React.useState(false)
    const [pdfIsReady, setPdfIsReady] = React.useState(false)
    const [eudgc, setEudgc] = React.useState<EUDCC1>();
    const [qrCodeValue, setQrCodeValue] = React.useState('');
    const [url] = React.useState<any>({
        api1: 'http://google.com/',
        api2: 'http://facebook.com/'
    })

    const [tan, setTAN] = React.useState('');

    const urlCertificateData = `/record/show/info/${eudgc?.dui}/${eudgc?.dob}`

    const [qrCodeForPDF, setQrCodeForPDF] = React.useState<any>();
    const [eudgcForPDF, setEudgcForPDF] = React.useState<EUDCC1>();
    const [valueSetsForPDF, setValueSetsForPDF] = React.useState<IValueSetList>();
    const [issuerCountryCodeForPDF, setIssuerCountryCodeForPDF] = React.useState('');

    const pdf = usePdfGenerator(qrCodeForPDF, eudgcForPDF, valueSetsForPDF, issuerCountryCodeForPDF, (isInit) => setPdfIsInit(isInit), (isReady) => setPdfIsReady(isReady));


    React.useEffect(() => {
        setEudgc(props.eudgc)
        if (props.eudgc) {
            // TODO catch errors and handle them du to possible server connection problems
            genEDGCQR(props.eudgc, props.certificado)
                .then((certResult: CertResult) => {
                    setQrCodeValue(certResult.qrCode);
                    setTAN(certResult.tan);
                    setIssuerCountryCodeForPDF(certResult.issuerCountryCode);
                    setQrCodeForPDF(document.getElementById('qr-code-pdf'));
                    //setEudgcForPDF(eudgc);
                    setEudgcForPDF(props.eudgc);
                    //setPdfIsReady(true)
                    //handleGeneratePdf()
                })
                .catch(error => {
                    handleError(error);
                });
        } else {
            props.setError({
                error: '',
                message: t('translation:error-patient-data-load'),
                onCancel: context.navigation!.toLanding
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eudgc])

    //comente este useeffect
    // React.useEffect(() => {
    //     if (pdf) {
    //         handleShowPdf();
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pdfIsReady]);

    React.useEffect(() => {
        if (context.navigation && context.valueSets)
            setIsInit(true);
        setValueSetsForPDF(context.valueSets);
    }, [context.navigation, context.valueSets])


    const handleError = (error: any) => {
        let msg = '';

        if (error) {
            msg = error.message
        }
        props.setError({ error: error, message: msg, onCancel: context.navigation!.toLanding });
    }


    // const handleGeneratePdf = () => {
    //     setQrCodeForPDF(document.getElementById('qr-code-pdf'));
    //     setEudgcForPDF(eudgc);
    //     setPdfIsReady(true)
    //     //handleShowPdf();
    // }

    const handleDonwloadQr = () => {
        // var link = document.createElement('a');
        const name = eudgc?.nam?.gn + ' ' + eudgc?.nam?.fn;
        let filename = name.split(/[ ]+/).join('_')
        // link.download = filename + '.png';
        // // validate if the browser supports the download attribute
        // let hrefBase = window.document.getElementById('qr-code-pdf') as HTMLCanvasElement;
        // if (hrefBase !== null) {
        //     link.href = hrefBase.toDataURL('image/png');
        //     link.click();
        // }

        let canva = window.document.getElementById('qr-code-pdf') as HTMLCanvasElement
        canva.toBlob(function (blob: any) {
            var link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = filename + '.png';
            //let isString = btoa(blob)
            //isString = "data: image/png" + ";base64," + isString;
            link.click();
            URL.revokeObjectURL(url);
        })
    }

    // //tomamos en que dispositivo se esta usando la app
    const [osCurrent, setOsCurrent] = React.useState(false);
    React.useEffect(() => {
        //window.alert(navigator.userAgent)
        //validamos en que dispositivo estamos
        if (navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)) {
            //window.alert("esta en iphone");

            //validamos en que navegador estamos actualmente
            const chrome = navigator.userAgent.indexOf('CriOS') > -1;
            const firefox = navigator.userAgent.indexOf('FxiOS') > -1
            if (chrome) {
                setOsCurrent(true)
            }
            if (firefox) {
                setOsCurrent(true)
            }
        } else {
            setOsCurrent(false)
        }
    }, [])


    const handleShowPdf = () => {
        if (pdf) {
            const blobPDF = new Blob([pdf.output('blob')], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blobPDF);
            let tempLink = document.createElement('a');
            /*tempLink.href = blobUrl;
            tempLink.setAttribute('download', 'Certificate.pdf');
            tempLink.click();*/
            //validando el dispositivo en el que se esta viendo la aplicacion
            if (navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/OS/i)
            ) {
                tempLink.href = blobUrl;
                tempLink.setAttribute('download', 'Certificate.pdf');
                tempLink.click();
            } else {
                window.open(blobUrl);
            }
        }
    }


    return (
        !(isInit && context.valueSets && eudgc && qrCodeValue) ?
            < div className="d-flex justify-content-center align-items-center" style={{ gap: '3rem' }}>
                <Spinner animation="border" as="div" variant="light" />
            </div> :
            <>
                <Fade appear={true} in={true}>
                    <Card id='data-card' className='p-3 rounded-lg'>
                        {/*    content area with patient inputs and check box    */}
                        <Card.Header id='data-header' className='p-3'>
                            <Row>
                                <Col sm='6' className='pl-3'>
                                    <Card.Title className='m-md-0 tac-xs-tal-md jcc-xs-jcfs-md'
                                        as={'h3'}>{t('translation:your-certificate')}</Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body id='data-body'>
                            <Row>
                                <Col sm='6' className='p-3'>

                                    <ShowCertificateData eudgc={eudgc} valueSetList={context.valueSets} />

                                </Col>
                                <Col sm='6' className='p-3'>
                                    <Container id='qr-code-container'>
                                        {qrCodeValue ? <><QRCode id='qr-code' size={256} renderAs='svg'
                                            value={qrCodeValue} />
                                            {/* <Card.Text className='input-label' >{qrCodeValue}</Card.Text> */}
                                        </> : <></>}
                                    </Container>
                                    <Container id='qr-code-container' className='hidden'>
                                        {qrCodeValue ? <> <QRCode id='qr-code-pdf' size={256} renderAs='canvas'
                                            value={qrCodeValue} />
                                        </> : <></>}
                                    </Container>
                                    <Card.Text className='input-label jcc-xs-sm m-3 text-center'>TAN: {tan}</Card.Text>
                                    {/* <Container id='qr-code-container2'>
                                        {qrCodeValue ? <><QRCode id='qr-code' size={256} renderAs='svg'
                                            value={"https://certificado.covid19.gob.sv" + urlCertificateData} />
                                        </> : <></>}
                                    </Container>
                                    <Container>
                                        <Row className='text-center'>
                                            <Col>
                                                <a href={urlCertificateData} target="_blank">QR verificar en linea</a>
                                            </Col>
                                        </Row>
                                    </Container> */}
                                </Col>
                            </Row>
                        </Card.Body>

                        {/*    footer with correction and finish button    */}
                        <Card.Footer id='data-footer'>
                            <Row>
                                <Col xs='12' sm='12' md='6' className='p-3'>
                                    <Button
                                        className='w-75 mx-auto button-generar mb-3'
                                        block
                                        onClick={handleShowPdf}
                                    >
                                        {t('translation:generate-pdf')}
                                    </Button>
                                </Col>
                                <Col xs='12' sm='12' md='6' className='p-3'>
                                    {
                                        osCurrent ? (
                                            <></>
                                        ) : (
                                            <Button
                                                className='w-75 mx-auto button-generar mb-3'
                                                block
                                                onClick={handleDonwloadQr}
                                            >
                                                Descargar Código QR
                                            </Button>
                                        )
                                    }
                                </Col>
                            </Row>

                        </Card.Footer>

                        <Row style={{ textAlign: 'center' }}>
                            <Col xs='12' md='12' className='pl-md-0 pr-md-2 pb-3 pb-md-0'>
                                <Container>
                                    {/* <img src={logo_goes_negro}  /> */}
                                </Container>
                            </Col>
                        </Row>
                    </Card>
                </Fade>
            </>

    )
}

export default ShowCertificate;